<template>
  <div id="video-downloads">
    <div v-if="loading" class="loading"></div>
    <div v-else class="video-download-list">
      <div v-for="item in videos" :key="item.id" class="video-item">
        <img :src="item.image" class="img-responsive" />
        <div>
          <h3>{{ item.name }}</h3>
          <a :href="item.download" target="_blank" download class="text-link"
            >Download</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { brandKeys } from "@/mixins/brandKeys.js";
export default {
  name: "VideoDownloads",
  mixins: [brandKeys],
  data() {
    return {
      videos: [],
      loading: true,
      access_token: "",
      offset: 0,
      count: null,
      account: "2519281010001",
    };
  },
  mounted() {
    this.getAuth();
  },
  methods: {
    getAuth() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      fetch("https://script.google.com/macros/s/AKfycbwOeVpdrx4AjU2mJdtPhzsM-H3G2h-reLMuCpI9cpNEkSD5OyvTIDdP-PChXthIO4hA/exec", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.access_token = result;
          this.getCount()
        })
        .catch((error) => console.log("error", error))
    },
    getCount() {
      var requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        redirect: "follow",
      };
      fetch(`https://cms.api.brightcove.com/v1/accounts/${this.account}/counts/videos?query=(+visibility:Public) AND (+brands:"${this.brandName}")`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.count = result.count;
          this.getVideos()
        })
        .catch((error) => console.log("error", error))
    },
    async getVideos() {
      var limit = 100;
      var requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        redirect: "follow",
      };
      while (this.offset <= this.count) {
        await fetch(
          `https://cms.api.brightcove.com/v1/accounts/${this.account}/videos?sort=name&query=(+visibility:Public) AND (+brands:"${this.brandName}")&limit=${limit}&offset=${this.offset}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            result.forEach((video) => {
              this.getDownloadLink(
                video.id,
                video.name,
                video.images.thumbnail.src
              );
            });
          })
          .catch((error) => console.log("error", error));
        this.offset += limit;
        this.loading = false;
      }
    },
    getDownloadLink(id, name, image) {
      var requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        redirect: "follow",
      };
      fetch(
        `https://cms.api.brightcove.com/v1/accounts/2519281010001/videos/${id}/sources`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.videos.push({
            id: id,
            name: name,
            image: image,
            download: result[4].src,
          });
        })
        .catch((error) => console.log("error", error));
    },
  },
  metaInfo() {
    return {
      title: "Video Library",
      titleTemplate: `%s | ${this.brandName}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `View the Latest videos from ${this.brandName}`,
        },
      ],
    };
  },
};
</script>

<style scoped>
#video-downloads {
  position: relative;
  min-height: 100vh;
}
.loading {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%);
}
.loading:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #d8d8d8;
  border-color: #d8d8d8 transparent #d8d8d8 transparent;
  animation: loading 1.2s linear infinite;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.video-item {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  margin-bottom: 3rem;
  flex-wrap: nowrap;
}
.video-item h3 {
  margin-top: 0.5rem;
}
.video-item img {
  width: 175px;
  height: 98px;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .video-item img {
    width: 100px;
    height: 56px;
  }
}
</style>